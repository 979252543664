import React, { Component } from 'react';
import { FiEdit } from 'react-icons/fi';
import { Link } from "react-router-dom";

const userData =  JSON.parse(localStorage.getItem("_userData"));
let EDIT_ACCESS = userData && userData.dash_admin ? true : false; 

class PartnerBlocks extends Component {

    constructor (props) {
        super(props)
    
        this.state = {
          data: []
        }
        this.imageAlign = props.imageAlign;
    }

    static getDerivedStateFromProps(props, state) {
      return {data: props.data };
    }
    
  render() {
    const { data } = this.state;
    return (
      <>
      { data && data.items &&
        <div className="mb-8 md:mt-20 md:mb-16 grid grid-cols-1 md:grid-cols-2  lg:grid-cols-4 gap-8 xl:gap-20">
            {
                data.items.map((item, index) => {
                    return (
                    <div key={index} className="item relative">
                        <div className="rounded-2xl image-box w-full h-[240px] lg:h-[300px] bg-white bg-no-repeat bg-contain bg-center"  style={{ backgroundImage: `url("${item.image}")` }}></div>
                        <div className="content-box md:px-6">
                            <h2 className="text-xl md:text-2xl my-4 font-bold">{item.title}</h2>
                            <div className="text-sm line-clamp-4" dangerouslySetInnerHTML={{__html : item.description}}></div>
                            { item.btnAction && 
                              <>
                                  <Link to={`/${item.btnAction}`} className="inline-block mt-4 btn text-primary border border-primary hover:bg-primary transition-all duration-300 py-3 px-8 rounded-md text-sm hover:text-white">
                                    {item.btnLabel}
                                  </Link>
                              </>
                            }
                        </div>
                        {
                            EDIT_ACCESS && item.editLink &&
                            <a href={item.editLink.replace("ITEM_ID", item.hash)} target="_blank" className='absolute right-0 top-0 z-[1] underline  bg-primary text-white px-2 py-1 hover:bg-primary/70 text-sm editMode'><FiEdit className='text-lg' title="Edit" /></a>
                        }
                    </div>
                    );
                })
            }
        </div>
      }
      </>
    );
  }
}

export default PartnerBlocks;