import React, { Component} from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import Header from "./../compornents/Header";
import Footer from "./../compornents/Footer";
import PartnerFeeds from "./../compornents/PartnerFeeds";
import ImageTextBoxes from "../compornents/ImageTextBoxes";
import Banner from "./../compornents/Banner";
import ApiError from "./../compornents/ApiError";
import Loader from "./../compornents/Loader";
import { api } from "../Settings";

class Soc extends Component {

  constructor (props) {
      super(props)

      this.state = {
        isLoading: false,
        isError: false,
        componentData: []
      }
  }

  componentDidMount () {

      const that = this;
      that.setState({ isLoading: true, isError: false })
      axios.get(api.getSOC).then(response => {
        this.setState({
          componentData: response.data.components
        })
        this.setState({ isLoading: false });
      })
      .catch(function (error) {
        that.setState({ isLoading: false, isError: true  })
      })
  }


  render(){
    const { componentData, isLoading, isError } = this.state;
    return (
      <HelmetProvider>
        <div>
        <Helmet>
          <title> SOC - Explore Shop on Cloud </title>
        </Helmet>
        <Header/>
        <div className="container min-h-screen mx-auto mt-[120px] lg:flex xl:px-8">
            <div className="w-full">
            {isLoading ? <Loader /> : <><Banner gradient={true} data={componentData.Banner} /><ImageTextBoxes data={componentData.Blocks} /></>}
            {isError && <ApiError />}
          </div>
          {/* <div className="lg:w-1/4 lg:ml-4 xl:ml-8">
              <PartnerFeeds />
          </div> */}
        </div>
        <Footer />
        </div>
        </HelmetProvider>
    )
  }

}

export default Soc;


