import React, { Component} from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import Header from "./../compornents/Header";
import Footer from "./../compornents/Footer";
import PartnerFeeds from "./../compornents/PartnerFeeds";
import FAQList from "./../compornents/FAQList";
import Banner from "./../compornents/Banner";
import { api } from "../Settings";

class FAQ extends Component {

    constructor (props) {
        super(props)

        this.state = {
            data: []
        }
    }

    componentDidMount () {
        axios.get(api.getFAQ).then(response => {
          this.setState({
            data: response.data
          })
        })
    }

    render(){
        const { data } = this.state;
        return (
            <HelmetProvider>
                <div>
                <Helmet>
                <title> SOC - FAQ</title>
                </Helmet>
                <Header/>
                <div className="container min-h-screen mx-auto mt-[120px] lg:flex xl:px-8  relative z-0"> 
                    <div className="w-full">
                        {
                            data.components &&
                            <Banner gradient={true} data={data.components.Banner} />
                        }
                        <FAQList />
                    </div>
                  {/*   <div className="lg:w-1/4 lg:ml-4 xl:ml-8">
                        <PartnerFeeds />
                    </div> */}
                </div>
                <Footer />
                </div>
            </HelmetProvider>
        )
    }
}

export default FAQ;
