
import React, { Component} from "react";
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import Header from "../compornents/Header";
import Footer from "../compornents/Footer";
import PartnerFeeds from "../compornents/PartnerFeeds";
import FeaturedEventsSlider from "../compornents/FeaturedEventsSlider";
import FeaturedNewsSlider from "../compornents/FeaturedNewsSlider";
import Banner from "../compornents/Banner";
import Loader from "../compornents/Loader";
import ApiError from "../compornents/ApiError";
import { Link  } from "react-router-dom";


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}
  

class DetailPage extends Component {

  constructor (props) {
      super(props)

      this.state = {
        data: [],
        isLoading: false,
        isError: false
      }

      this.renderData = this.renderData.bind(this);
  }

  componentDidMount () {

      const { slug } = this.props.params;
      const dataSource = process.env.REACT_APP_API_URL + '/' + slug +'.json';
      const that = this;

      this.setState({
        slug: slug
      })

      axios.get(dataSource).then(response => {
        this.setState({
          data: response.data
        })

        that.setState({ isLoading: false })
      }).catch(function (error) {
        that.setState({ isLoading: false, isError: true })
      }); 
  }


  renderData(){
    const { data } = this.state;
    return (
      
      data &&
      <>
              <div className='my-6 flex items-start text-sm text-primary'>
                  <Link to="/">Home</Link><span className='mx-1'>/</span>
                  <Link to="/soc">Explore Shop on Cloud</Link><span className='mx-1'>/</span>
                  <span>{data.title}</span>
              </div>
              {
                !data.banner &&
                <h1 className="md:text-3xl font-semibold mt-4 lg:mt-12 mb-4">{data.title}</h1>
              }
              {
                  data.components && data.components.Banner &&
                  <Banner data={data.components.Banner} />
              }
              <div className="item-content detail-content mb-8">
                  <div className="text-base mt-8" dangerouslySetInnerHTML={{__html : data.description}}></div>
              </div>

      </>

    )

  }

  render(){
   
    const { isLoading, isError, slug } = this.state;
    return (
        <HelmetProvider>
            <div>
            <Helmet>
            <title>{ slug }</title>
            </Helmet>
            <Header/>
            <div className="container min-h-screen mx-auto mt-[120px] lg:flex xl:px-8  relative z-0">
              <div className="w-full">
                {isLoading ? <Loader /> : this.renderData()}
                {isError && <ApiError />}
               {/*  <FeaturedNewsSlider />
                <FeaturedEventsSlider /> */}
              </div>
             {/*  <div className="lg:w-1/4 lg:ml-4 xl:ml-8">
                <PartnerFeeds />
            </div> */}
            </div>
            <Footer />
            </div>
        </HelmetProvider>
    )
  }

}

export default withParams(DetailPage);;


