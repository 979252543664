import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { FaRegHandshake } from "react-icons/fa";
import { SlGraduation } from "react-icons/sl";
import { BiConversation } from "react-icons/bi";
import { MdOutlineClose } from "react-icons/md";
import { FiEdit } from 'react-icons/fi';

const userData =  JSON.parse(localStorage.getItem("_userData"));
let EDIT_ACCESS = userData && userData.dash_admin ? true : false; 

class Welcome extends Component {

    constructor (props) {
        super(props)
        this.state = {
          data: props.data,
          hide: false
        }

        this.hideContent = this.hideContent.bind(this);
        const visited = document.cookie.split('; ').find((row) => row.startsWith('visited='))?.split('=')[1];

        if(visited){
          this.state.hide = true;
        }
    }

    hideContent (e) {
      this.setState({ hide: true});
      document.cookie = "visited=true; Secure";
    }
    
    static getDerivedStateFromProps(props, state) {
      return {data: props.data };
    }
    
    render() {

      const userData =  JSON.parse(localStorage.getItem("_userData"));
      let userName = userData && userData.details.first_name ? `${userData.details.first_name}` : ''; 
      let lastName = userData && userData.details.last_name ? `${userData.details.last_name}` : ''; 

      const { data } = this.state;
      return (
        <>
        { data && 
        <div className={`overflow-hidden transition-all duration-300 ${this.state.hide ? "max-h-0" : "max-h-[1000px] relative"}`}>
          <div className={`shadow-lg pt-8 pb-8 md:p-4 2xl:p-16 text-body mb-16 relative`}>
              <h1 className="text-2xl md:text-3xl mb-8 font-semibold">{data.title} {userName} {lastName}</h1>
              <div className="text-sm" dangerouslySetInnerHTML={{__html : data.description}}></div>
             {/*  <ul className="mt-16 my-8 md:flex items-center">
                  <li className="text-primary mb-2 md:mb-0 md:mr-4 2xl:mr-8 flex items-center">
                    <Link to="/partners" className='flex items-center'>
                        <FaRegHandshake className="text-xl" /> <span className="pl-2 text-sm 2xl:text-base">{data.LabelBenefits}</span>
                    </Link>
                  </li>
                  <li className="text-primary  mb-2 md:mb-0 md:mr-4  2xl:mr-8  flex items-center">
                      <Link to="/partners"  className='flex items-center'>
                      <SlGraduation className="text-xl" /> <span className="pl-2 text-sm 2xl:text-base">{data.LabelPartner}</span>
                      </Link>
                  </li>
                  <li className="text-primary  mb-2 md:mb-0 md:mr-4 2xl:mr-8  flex items-center">
                      <Link to="/partners"  className='flex items-center'>
                       <BiConversation className="text-xl" /> <span className="pl-2 text-sm 2xl:text-base">{data.LabelFAQ}</span>
                      </Link>
                  </li>
              </ul> */}
              <div className="actions flex items-center mt-4">
                  <Link to={data.btnAction} className="btn text-primary border border-primary hover:bg-primary transition-all duration-300  py-2 px-5 rounded-md text-sm hover:text-white">
                    {data.btnLabel}
                  </Link>
                  <span className="text-primary ml-8 cursor-pointer hover:opacity-70"  onClick={this.hideContent}>{data.skip}</span>
              </div>
              <MdOutlineClose  onClick={this.hideContent} className="absolute right-4 top-0 2xl:top-16 cursor-pointer text-3xl text-body/50" />
          </div>
          {
            EDIT_ACCESS && data.editLink &&
            <a href={data.editLink.replace("ITEM_ID", data.hash)} target="_blank" className='absolute right-0 top-0 z-[1] underline  bg-primary text-white px-2 py-1 hover:bg-primary/70 text-sm editMode'><FiEdit className='text-lg' title="Edit" /></a>
          }
        </div>
        }
        </>
      );
    }
}

export default Welcome;