import React, { Component} from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "./../compornents/Header";
import Footer from "./../compornents/Footer";
import PartnerFeeds from "./../compornents/PartnerFeeds";
import FeaturedRecipeSlider from "./../compornents/FeaturedRecipeSlider";
import RecipeGrid from "./../compornents/RecipeGrid";
import { Link  } from "react-router-dom";


class Recipe extends Component {

    constructor (props) {
        super(props)

        this.state = {
            data: []
        }
    }

    componentDidMount () {
      /*   axios.get(api.getNews).then(response => {

        console.log(response);
          this.setState({
            data: response.data
          })
        }) */
    }

    render(){
        const { data } = this.state;
        return (
            <HelmetProvider>
                <div>
                <Helmet>
                <title> SOC - Recipes </title>
                </Helmet>
                <Header/>
                <div className="container min-h-screen mx-auto mt-[120px] lg:flex xl:px-8 relative z-0">
                    <div className="lg:w-3/4">
                        <div className='my-6 flex items-center text-sm text-primary'>
                            <Link to="/">Home</Link><span className='mx-1'>/</span>
                            <span>Recipe</span>
                        </div>
                        <FeaturedRecipeSlider />
                        <RecipeGrid />
                    </div>
                    <div className="lg:w-1/4 lg:ml-4 xl:ml-8">
                        <PartnerFeeds />
                    </div>
                </div>
                <Footer />
                </div>
            </HelmetProvider>
        )
    }
}

export default Recipe;
