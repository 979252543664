import React, { Component} from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate, Navigate  } from "react-router-dom";
import NewPasswordForm from "./../compornents/NewPasswordForm";

const userData =  JSON.parse(localStorage.getItem("_userData"));
const ASSCESS_TOKEN = (userData) && userData.data.access_token;

function withParams(Component) {
  return props => <Component {...props} navigation={useNavigate()} />;
}

class NewPassword extends Component {

    constructor (props) {
        super(props);

        this.state = {
          componentData: []
        }

    }

    componentDidMount () {
     
      if(ASSCESS_TOKEN){
        console.log(ASSCESS_TOKEN);

       // setTimeout(function(){
        //  navigation("/");
    //      navigation(0)
      //  },100);
      
      }

      /*   axios.get(dataSource).then(response => {

          console.log(response);
          this.setState({
            componentData: response.data.components
          })
        }) */
    }


  render(){

   

    // useEffect(() => {
    //   if(!state.length){
    //     navigation("/")
    //   }
    // }, []); 

    const { navigation } = this.props;
    if (ASSCESS_TOKEN) {
      return <Navigate to="/" />;
    }
    
    const { componentData } = this.state;
    return (
      <HelmetProvider>
        <div>
        <Helmet>
          <title> SOC - New Password </title>
        </Helmet>
        <div className="container flex items-center justify-center mx-auto  px-8  h-full h-screen w-full">
              <NewPasswordForm  />
        </div>
        </div>
        </HelmetProvider>
    )
  }
}

export default withParams(NewPassword);
