import React, { Component, createRef } from 'react';
import logo from './../assets/logo.svg';
import axios from 'axios';
import { BiLoaderAlt } from 'react-icons/bi';
import { useNavigate, NavLink } from "react-router-dom";
import { api } from "../Settings";
import ReCAPTCHA  from "react-google-recaptcha";


function withParams(Component) {
    return props => <Component {...props} navigation={useNavigate()} />;
}
  

class NewPwForm extends Component {

    constructor (props) {
        super(props);
        this.recaptchaRef  = createRef();

        this.state = {
            settings: null,
            password: '', 
            confirmPassword: '', 
            passwordError: '',
            confirmPasswordError: '',
            apiError: '',
            token: '',
            key: '',
            successMessage: '',
            isLoading: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validate = this.validate.bind(this);
        this.sendData = this.sendData.bind(this);

        let searchParams = new URLSearchParams(window.location.search);
        this.state.key  = searchParams.get('key');
    }

    componentDidMount () {
      axios.get(api.getSettings).then(response => {
        console.log( response.data);
        this.setState({
          settings: response.data
        })
      })
    }


    handleChange(event) {
        const target = event.target;
        const val = (target.value) ? target.value : "";
        const value = target.type === 'checkbox' ? target.checked : val;
        const name = target.name;
        this.setState({ [name]: value    });
        this.setState({  apiError: "",  passwordError : "", confirmPasswordError:"", successMessage: ""});
    }

    validate() {
      let passwordError = "";
      let confirmPasswordError = "";
      let apiError = "";

      this.setState({  apiError: "" });

      const reg =  /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
      if (!this.state.password || reg.test(this.state.password) === false) {
        passwordError = "Use at least 8 characters, Password should contain numbers and letters. ";
      }else if (this.state.password  && this.state.password !== this.state.confirmPassword) {
        confirmPasswordError = "Password Must be Matching. ";
      }


      if (passwordError) {
        this.setState({  passwordError });
        return false;
      }
      if (confirmPasswordError) {
        this.setState({  confirmPasswordError });
        return false;
      }
      return true;
    }
 

    handleSubmit  =  (event) =>  {

      event.preventDefault();

      if (this.validate()) {
        this.sendData();
      }

    }

    sendData  = async () =>  {

      const token = await this.recaptchaRef.current.executeAsync();
      //const { navigation } = this.props;

      let that = this;
      that.setState({  isLoading: true });

      axios.post('/api/new-password', {
        pwd: this.state.password,
        cnfpwd: this.state.confirmPassword,
        key: this.state.key,
        token: token
      })
      .then(function (response) {

            let data = response.data;
            if(data.success ){
                  that.setState({  successMessage: 'Your password has been changed successfully.' });
            }else{
              that.setState({  apiError: data.message });
            }

            that.setState({  isLoading: false });
      })
      .catch(function (error) {
        that.setState({  isLoading: false,  apiError: 'Unable to connect with the api.' });
        console.log(error);

        //that.setState({  successMessage: 'Your password has been changed successfully.' });
      });

    }
    

    render() {
      const { settings } = this.state;
      return (
        <>
        <div className='mx-auto w-[380px]'>
            <div className="w-full text-left text-sm">
                <form className="bg-white" onSubmit={this.handleSubmit}>
                    <div className='text-center'>
                        <img className="logo mx-auto mb-6" width="138" height="auto" src={logo} />
                        <h1 className='text-2xl md:text-3xl mb-8 font-normal'>
                            { !this.state.successMessage ? " New Password" : "Password Changed!" }
                        </h1>
                    </div>
                    {
                      !this.state.successMessage &&
                      <>
                        <div className="mb-2 mb:mb-4">
                          <label className="block text-gray-700  font-semibold mb-2" htmlFor="username">New password</label>
                          <input  onChange={this.handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.password || ''}   name="password" type="password" placeholder="************" ></input>
                          <span className="text-red-500 text-xs italic">{this.state.passwordError}</span>
                        </div>
                        <div className="mb-2 md:mb-4">
                        <label className="block text-gray-700 font-semibold mb-2"  htmlFor="password">
                            Confirm Password
                        </label>
                        <input  onChange={this.handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.confirmPassword || ''}   name="confirmPassword" type="password" placeholder="************"></input>
                        <span className="text-red-500 text-xs italic">{this.state.confirmPasswordError}</span>
                      
                        </div>
                      </>
                    }
                    {
                      !this.state.successMessage && settings && settings.dammore && settings.dammore.google_captcha_key &&
                      <ReCAPTCHA  ref={this.recaptchaRef} size="invisible" sitekey={settings.dammore.google_captcha_key} />
                    }
                    {
                      !this.state.successMessage &&
                      <div className="text-center">
                        { 
                          !this.state.isLoading &&
                          <button type='submit' className="inline-block w-full  mt-2 md:mt-5 btn bg-primary shadow-md py-3 px-5 rounded-md text-white hover:shadow-lg hover:opacity-80">Reset</button>
                        }
                        {
                          this.state.isLoading &&
                          <button type='button' className="w-full  mt-2 md:mt-5 btn bg-primary py-3 px-5 rounded-md text-white shadow-lg opacity-80 flex items-center justify-center cursor-default">
                              <BiLoaderAlt className="animate-spin h-5 w-5 mr-2"  /> Loading...
                          </button>
                        }
                          <span className="text-red-500 text-sm italic mt-2 text-left block">{this.state.apiError}</span>
                          <p className='mt-4'><NavLink to="/forget-password" 
                          className='font-semibold'>Reset the password again</NavLink></p> 
                      </div>
                    }
                    {
                      this.state.successMessage  &&
                      <>
                      <span className="text-primary text-base italic mt-2 mb-8 block text-center">{this.state.successMessage}
                      <br/><NavLink to="/login" className="hover:text-primary/70">Click here to <span className='font-semibold'>Login</span></NavLink></span>
                      </>
                    }
                </form>
            </div>
          
        </div>
        </>
      );
    }
}

export default withParams(NewPwForm);